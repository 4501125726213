<template>
  <div class="mt-8 flex flex-col space-y-4">
    <h2
      class="heading-lg text-balance pb-1 leading-none print:!text-black print:dark:!text-black"
      v-text="t('headline')"
    />

    <Divider />

    <div v-for="concession in orderConcessions">
      <OrderConcession :concession />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OrderConcession } from '#gql/default'

interface Props {
  orderConcessions: OrderConcession[]
}

const { orderConcessions } = defineProps<Props>()

const { t } = useI18n()

defineOptions({
  name: 'OrderConfirmationConcessions',
})
</script>

<i18n>
de:
  headline: 'Mitgebuchte Menüs'
es:
  headline: 'Menús compartidos'
en:
  headline: 'Menus'
</i18n>
