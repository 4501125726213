<template>
  <OrderConfirmationTypeTicket
    v-if="order.type === TICKET"
    :order
    :purchase-data
  />

  <OrderConfirmationTypeVoucher
    v-if="order.type === VOUCHERBUY"
    :order
    :purchase-data
  />

  <OrderConfirmationTypeShop v-if="order.type === SHOP" :order :purchase-data />

  <OrderConfirmationTypeCardCharging
    v-if="order.type === CARDCHARGING"
    :order
    :purchase-data
  />

  <OrderConfirmationTypeSubscription
    v-if="order.type === SUBSCRIPTION"
    :order
    :purchase-data
  />
</template>

<script setup lang="ts">
import type { PurchaseData } from '@account/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

defineProps<Props>()

const { TICKET, VOUCHERBUY, SHOP, CARDCHARGING, SUBSCRIPTION } = ORDER_TYPE

defineOptions({
  name: 'AccountOrderConfirmation',
})
</script>
