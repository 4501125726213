<template>
  <OrderConfirmationOrderOverviewWrapper
    :order
    :show-presale-disclaimer="false"
  >
    <div class="grid grid-cols-3 py-2">
      <span v-text="t('cinema')" />
      <span class="col-span-2" v-text="cinemaString" />

      <span v-text="t('orderNumber')" />
      <span class="col-span-2" v-text="order.orderNumber" />

      <span v-text="t('vouchers')" />
      <span class="col-span-2" v-text="`1x ${order.subscriptions[0].name}`" />

      <span v-text="t('validUntil')" />
      <span class="col-span-2" v-text="`TODO`" />
    </div>
  </OrderConfirmationOrderOverviewWrapper>
</template>

<script setup lang="ts">
import type { PurchaseData } from '@account/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

const { order } = defineProps<Props>()

const { t } = useI18n()

const cinemaString = formatCinemaAddressFromOrder(order)

defineOptions({
  name: 'OrderConfirmationOrderOverviewSubscription',
})
</script>

<i18n>
de:
  cinema: "Kino"
  orderNumber: "Bestellnummer"
  vouchers: "Gutscheine"
  validUntil: "Gültig bis"
es:
  cinema: "Cine"
  orderNumber: "Número de pedido"
  vouchers: "Vales"
  validUntil: "Válido hasta"
en:
  cinema: "Cinema"
  orderNumber: "Order number"
  vouchers: "Vouchers"
  validUntil: "Valid until"
</i18n>
