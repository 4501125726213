<template>
  <PageContainer
    v-if="order && purchaseData"
    :as="PAGE_CONTAINER_TAG.ARTICLE"
    class="space-y-4"
  >
    <OrderConfirmation
      v-if="appConfig?.enableNewThankYouPage"
      :order
      :purchase-data
    />
    <OrderWidget v-else :order />
  </PageContainer>
</template>

<script lang="ts" setup>
import { PAGE_CONTAINER_TAG } from '@base/constants/page-container'

const route = useRoute()
const { t, setLocale } = useI18n()

const appConfig = useAppConfig()

if (route.query.lang === 'en') {
  setLocale('en')
  useGqlHeaders({ 'Accept-Language': 'en' })
}

const { order, purchaseData } = await useOrder({
  orderNumber: route.params.orderNumber as string,
  securityKey: route.params.securityKey as string,
  design: route.query.design as string,
})

const { $gtm } = useNuxtApp()

if (!order.value) {
  showError({
    statusCode: 404,
    statusMessage: 'pageNotFound',
  })
}

usePageMetas({
  title: `${t('pageTitle.order')} ${order.value.orderNumber}`,
})

onMounted(() => {
  $gtm.trackPageView()
  $gtm.push({
    event: 'purchase',
    ecommerce: {
      purchase: purchaseData.value,
    },
  })
})

defineI18nRoute({
  paths: {
    en: '/account/order/[orderNumber]/[securityKey]',
    de: '/konto/bestellung/[orderNumber]/[securityKey]',
    es: '/cuenta/compra/[orderNumber]/[securityKey]',
  },
})

defineOptions({
  name: 'AccountOrder',
})
</script>

<i18n>
de:
  pageTitle:
    order: "Bestellung"
es:
  pageTitle:
    order: "Orden"
en:
  pageTitle:
    order: "Order"
</i18n>
