<template>
  <div id="rokt-placeholder" data-rokt-widget />
</template>

<script lang="ts" setup>
interface Props {
  attributes: string
}

declare global {
  interface Window {
    Rokt: any
  }
}

const { attributes } = defineProps<Props>()

const appConfig = useAppConfig()
const { getConsentForDomain } = useConsentManager()
const hasConsent = computed(() => getConsentForDomain('apps.rokt.com'))

const { load, unload } = useScriptTag(
  'https://apps.rokt.com/wsdk/integrations/launcher.js',
  initWidget,
  {
    crossOrigin: 'anonymous',
    async: true,
    attrs: {
      id: 'rokt-launcher',
    },
    manual: true,
    fetchPriority: 'high',
    type: 'module',
  }
)

async function initWidget() {
  const launcher = await window.Rokt?.createLauncher({
    accountId: appConfig.roktAccountId,
    noFunctional: false,
    noTargeting: true,
    sandbox: true,
  })

  await launcher.selectPlacements(JSON.parse(attributes))
}

onMounted(async () => {
  if (hasConsent.value) {
    try {
      await load()
    } catch (error) {
      console.error(error)
    }
  }
})

onUnmounted(() => {
  unload()
})

defineOptions({
  name: 'RoktWidget',
})
</script>
