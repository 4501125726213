<template>
  <div class="inline-block text-center">
    <div class="border-neutral bg-light inline-block border">
      <NuxtImg
        :src="image"
        class="m-0"
        :alt="t('altText')"
        :class="sizeClass"
      />
    </div>
    <div v-if="showCode && code" class="text-xs leading-none" v-text="code" />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  image: string
  code: string
  showCode?: boolean
  size?: 'sm' | 'md' | 'lg'
}

const { showCode = true, size = 'md' } = defineProps<Props>()

const { t } = useI18n()

const sizeClass = computed(() => {
  return {
    'size-16': size === 'sm',
    'size-24': size === 'md',
    'size-32': size === 'lg',
  }
})

defineOptions({
  name: 'QrCode',
})
</script>

<i18n>
de:
  altText: "QR-Code"
en:
  altText: "QR-Code"
es:
  altText: "Código QR"
</i18n>
