<template>
  <div
    class="inline-flex shrink-0 items-center rounded-full px-2 py-1 leading-none"
    :class="themeClasses"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { BADGE_THEME } from '@base/constants/badge'
import type { BadgeTheme } from '@base/types/badge'

interface Props {
  theme?: BadgeTheme
}

const { theme = 'primary' } = defineProps<Props>()

console.log(theme)

const themeClasses = computed(() => ({
  'bg-primary text-on-primary': theme === BADGE_THEME.PRIMARY,
  'bg-system-success text-on-system-success': theme === BADGE_THEME.SUCCESS,
  'bg-system-error text-on-system-error': theme === BADGE_THEME.ERROR,
  'bg-system-info text-on-system-info': theme === BADGE_THEME.INFO,
}))

defineOptions({
  name: 'Badge',
})
</script>
