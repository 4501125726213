<template>
  <div class="flex flex-col text-right">
    <span v-text="priceTotalString" />
    <span
      v-if="showPresaleDisclaimer"
      class="italic"
      v-text="t('presaleDisclaimer')"
    />
  </div>
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
  showPresaleDisclaimer?: boolean
}

const { order, showPresaleDisclaimer = true } = defineProps<Props>()

const { t, n } = useI18n()

const priceTotalString = computed(() => {
  return `${label.value}: ${n(getTotalPriceFromOrder(order), 'currency')}`
})

const label = computed(() => {
  switch (order.payment) {
    case PAYMENT_METHOD.CUSTOMER_CARD:
      return t('customerCardCharging')
    default:
      return t('totalPrice')
  }
})

defineOptions({
  name: 'OrderConfirmationOrderOverviewPrice',
})
</script>

<i18n>
de:
  totalPrice: "Gesamtpreis"
  customerCardCharging: "Belastung der Kundenkarte"
  presaleDisclaimer: "Im Ticket- und Warenpreis ist eine Vorverkaufsgebühr i.H.v. 10% enthalten."
es:
  totalPrice: "Precio total"
  customerCardCharging: "Cargo a la tarjeta del cliente"
  presaleDisclaimer: "El precio de la entrada y de los productos incluye una comisión de venta anticipada del 10%."
en:
  totalPrice: "Total price"
  customerCardCharging: "Customer card charging"
  presaleDisclaimer: "The price of the ticket and products includes a pre-sale fee of 10%."
</i18n>
