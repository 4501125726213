<script setup lang="ts">
const { t } = useI18n()
const { icons } = useDesign()
const { changeState } = useAuthState()

defineOptions({
  name: 'AuthResetPasswordConfirmation',
})
</script>

<template>
  <div class="flex flex-col items-center space-y-8 text-center">
    <Icon :name="icons.MAIL_SENT" class="size-20 fill-black dark:fill-white" />

    <span class="font-bold" v-text="t('emailWithPasswordSent')" />

    <span v-text="t('checkEmail')" />

    <Button
      :text="t('backToLogin')"
      class="mt-4"
      @click="changeState(USER_AUTH_STATE.LOGIN)"
    />
  </div>
</template>

<i18n>
de:
  emailWithPasswordSent: "E-Mail zum Zurücksetzen des Passworts wurde versendet."
  checkEmail: "Bitte prüfe dein E-Mail-Postfach und klicke auf den Link um ein neues Passwort festzulegen."
  backToLogin: "Zurück zur Anmeldung"
es:
  emailWithPasswordSent: "Correo electrónico para restablecer la contraseña enviado."
  checkEmail: "Por favor, comprueba tu bandeja de entrada y haz clic en el enlace para establecer una nueva contraseña."
  backToLogin: "Volver al inicio de sesión"
</i18n>
