<template>
  <div
    class="@lg:grid-cols-3 @xl:grid-cols-4 @2xl:grid-cols-5 grid grid-cols-2 gap-4"
  >
    <WatchListMovieCard
      v-for="movie in watchList"
      :key="movie.id"
      :movie="movie"
      :hide-link="false"
      :render-above-the-fold="true"
      :link-params="[]"
      @remove="removeMovie({ movieId: movie.id, timeout: 2000 })"
      @re-add="addMovie({ movieId: movie.id })"
    />
  </div>
</template>

<script setup lang="ts">
import type { Movie } from '#gql/default'

interface Props {
  watchList: Movie[]
}

defineProps<Props>()

const { t } = useI18n()
const { add: addMessage } = useMessage()
const { add, remove } = useWatchList()

function addMovie({ movieId }: { movieId: string }) {
  try {
    add({ movieId })
  } catch (error) {
    addMessage({
      title: t('errorAddingMovie'),
      message: t('tryAgain'),
    })
  }
}

function removeMovie({
  movieId,
  timeout,
}: {
  movieId: string
  timeout: number
}) {
  try {
    remove({ movieId, timeout })
  } catch (error) {
    addMessage({
      title: t('errorRemovingMovie'),
      message: t('tryAgain'),
    })
  }
}

defineOptions({
  name: 'WatchListMovieList'
})
</script>

<i18n>
de:
  errorAddingMovie: "Fehler beim Hinzufügen des Films"
  errorRemovingMovie: "Fehler beim Entfernen des Films"
es:
  errorAddingMovie: "Error al agregar la película"
  errorRemovingMovie: "Error al eliminar la película"
</i18n>
