<template>
  <OrderConfirmationOrderOverviewWrapper
    :order
    :show-presale-disclaimer="false"
  >
    <div class="grid grid-cols-3 py-2">
      <span v-text="t('cinema')" />
      <span class="col-span-2" v-text="cinemaString" />

      <span v-text="t('orderNumber')" />
      <span class="col-span-2" v-text="order.orderNumber" />

      <span v-text="t('deliveryAddress')" />
      <span class="col-span-2" v-text="shippingAddressString" />
    </div>
  </OrderConfirmationOrderOverviewWrapper>
</template>

<script setup lang="ts">
import type { PurchaseData } from '@base/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

const { order } = defineProps<Props>()

const { t } = useI18n()

const cinemaString = formatCinemaAddressFromOrder(order)
const shippingAddressString = [
  order.contact.shippingAddress.name,
  order.contact.shippingAddress.street,
  order.contact.shippingAddress.city,
  order.contact.shippingAddress.zipCode,
  order.contact.shippingAddress.country,
].join(', ')

defineOptions({
  name: 'OrderConfirmationOrderOverviewShop',
})
</script>

<i18n>
de:
  cinema: "Kino"
  orderNumber: "Bestellnummer"
  deliveryAddress: "Versandadresse"
es:
  cinema: "Cine"
  orderNumber: "Número de pedido"
  deliveryAddress: "Dirección de entrega"
en:
  cinema: "Cinema"
  orderNumber: "Order number"
  deliveryAddress: "Delivery address"
</i18n>
