<template>
  <PageContainer class="flex-grow py-8">
    <PageGrid class="h-full">
      <WatchList />
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
const { t } = useI18n()

defineI18nRoute({
  paths: {
    de: '/konto/merkliste',
    es: '/cuenta/marcadores',
  },
})

usePageMetas({
  title: t('watchList'),
  description: t('watchListHint'),
  jsonLD: null,
  twitterTags: [],
  openGraphTags: [],
})

defineOptions({
  name: 'AccountWatchListPage',
})
</script>

<i18n>
de:
  watchList: "Merkliste"
  watchListHint: "Verwalte deine Lieblingsfilme mit Leichtigkeit: Speichere sie zentral und greife von überall und mit jedem Gerät auf deine persönliche Merkliste zu."
es:
  watchList: "Marcadores"
  watchListHint: "Administra tus películas favoritas con facilidad: guárdalas de forma centralizada y accede a tu lista de seguimiento personal desde cualquier lugar y dispositivo."
</i18n>
