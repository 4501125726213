<script setup lang="ts">
import type { Movie } from '#gql/default'
import { USER_AUTH_STATE } from '@account/constants/user'
import { BUTTON_THEME } from '@base/constants/button'
import { MOVIE_LIST_LAYOUT } from '@base/constants/movie-list'

const localeRoute = useLocaleRoute()
const { t } = useI18n()
const { add: addMessage } = useMessage()
const { movies, fetch, paginator } = useWatchList()
const { icons } = useDesign()
const { isLoggedIn } = useUserAuth()

const page = ref(1)

watch(isLoggedIn, async () => {
  if (isLoggedIn.value) {
    try {
      await fetch({ page: page.value })
    } catch (error) {
      addMessage({
        title: t('errorFetchingWatchlist'),
        message: t('tryAgain'),
        type: MESSAGE_TYPE.TOAST,
        severity: MESSAGE_SEVERITY.ERROR,
      })
    }
  }
})

onBeforeMount(async () => {
  if (isLoggedIn.value) {
    try {
      await fetch({ page: page.value })
    } catch (error) {
      addMessage({
        title: t('errorFetchingWatchlist'),
        message: t('tryAgain'),
        type: MESSAGE_TYPE.TOAST,
        severity: MESSAGE_SEVERITY.ERROR,
      })
    }
  }
})

const authType = ref<UserAuthStateTypes>()
const isAuthModalVisible = ref(false)

function showModalWithState(state: UserAuthStateTypes) {
  authType.value = state
  isAuthModalVisible.value = true
}

const activeMovieFilterElement = ref<string>('allMovies')

const filteredMovies = computed(() => {
  if (!movies.value) {
    return
  }

  const sortedMovies = [...movies.value].sort((a: Movie, b: Movie) => {
    return a.title.localeCompare(b.title)
  })

  if (activeMovieFilterElement.value === 'allMovies') {
    return sortedMovies
  }

  return sortedMovies?.filter((movie: Movie & { hasShows: boolean }) => {
    return movie.hasShows
  })
})

defineOptions({
  name: 'WatchList',
})
</script>

<template>
  <PageGridColumn v-if="isLoggedIn">
    <PageHeading :title="t('title')" />

    <WatchListMovieButtons v-model:activeElement="activeMovieFilterElement" />

    <WatchListMovieList
      v-if="filteredMovies?.length"
      :watch-list="filteredMovies as Movie[]"
    />

    <div v-else class="flex flex-col items-center space-y-4 md:items-start">
      <span v-text="movies?.length ? t('noCurrentMovies') : t('noMovies')" />

      <NuxtLink
        :to="
          localeRoute({
            name: 'movies',
            ...(movies?.length ? { query: { periods: 'NOW_PLAYING' } } : {}),
          })
        "
        class="button button-primary button-base"
      >
        {{ movies?.length ? t('allCurrentMovies') : t('allMovies') }}
      </NuxtLink>
    </div>

    <FetchMoreButton
      v-if="paginator?.hasMorePages"
      :pending="false"
      @click="page++"
    />
  </PageGridColumn>

  <PageGridColumn v-else class="flex flex-col items-center">
    <Icon :name="icons.BOOKMARK_OUTLINE" class="size-11 fill-white" />

    <h1
      class="!text-dark dark:!text-light text-3xl font-bold sm:text-4xl"
      v-text="t('title')"
    />

    <span class="w-full text-center md:w-2/3" v-text="t('wishListHint')" />

    <div class="flex flex-col space-y-3">
      <Button
        :text="t('login')"
        @click="showModalWithState(USER_AUTH_STATE.LOGIN)"
        @close="isAuthModalVisible = false"
      />

      <Button
        :text="t('register')"
        :theme="BUTTON_THEME.SECONDARY"
        @click="showModalWithState(USER_AUTH_STATE.REGISTER)"
        @close="isAuthModalVisible = false"
      />
    </div>

    <AuthModal
      v-if="isAuthModalVisible"
      :type="authType!"
      @close="isAuthModalVisible = false"
    />
  </PageGridColumn>

  <PageGridColumn>
    <PageSectionHeading :title="t('currentHighlights')" class="mt-12" />
    <CatalogMovieList
      :layout="MOVIE_LIST_LAYOUT.HORIZONTAL"
      movie-list-id="35"
    />
  </PageGridColumn>
</template>

<i18n>
de:
  title: "Merkliste"
  wishListHint: "Verwalte deine Lieblingsfilme mit Leichtigkeit: Speichere sie zentral und greife von überall und mit jedem Gerät auf deine persönliche Merkliste zu."
  login: "Anmelden"
  register: "Registrieren"
  currentHighlights: "Aktuelle Highlights"
  noCurrentMovies: "Auf deiner Merkliste befinden sich derzeit keine Filme, die aktuell im Kino laufen. Sieh dir alle aktuellen Kinofilme an und entdecke dein nächstes Highlight."
  noMovies: "Du hast noch keine Filme auf deiner Merkliste. Du kannst auf der Filmseite über das Icon welche hinzufügen."
  allMovies: "Alle Filme"
  allCurrentMovies: "Alle aktuellen Filme"
  errorFetchingWatchlist: "Fehler beim Laden der Merkliste"
es:
  title: "Lista de deseos"
  wishListHint: "Administra tus películas favoritas con facilidad: guárdalas de forma centralizada y accede a tu lista de deseos personal desde cualquier lugar y con cualquier dispositivo."
  login: "Iniciar sesión"
  register: "Registrarse"
  currentHighlights: "Destacados actuales"
  noCurrentMovies: "Tu lista de deseos no contiene películas que actualmente se estén proyectando en el cine. Echa un vistazo a todas las películas actuales y descubre tu próximo favorito."
  noMovies: "Aún no has añadido ninguna película a tu lista de deseos. Puedes hacerlo en la página de la película a través del icono."
  allMovies: "Todas las películas"
  allCurrentMovies: "Todas las películas actuales"
  errorFetchingWatchlist: "Error al cargar la lista de deseos"
</i18n>
