<template>
  <div>
    <PageHeading :title="t('article')" :tag="PAGE_HEADING_TAG.H2" />
    <Divider />
    <div class="flex flex-col py-2 print:text-black">
      <span
        v-for="({ quantity, concession }, index) in order.shopItems"
        :key="index"
        v-text="`${quantity}x ${concession.name}`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
}

defineProps<Props>()

const { t } = useI18n()

defineOptions({
  name: 'OrderConfirmationShopItems',
})
</script>

<i18n>
de:
  article: "Artikel"
es:
  article: "Artículo"
en:
  article: "Article"
</i18n>
