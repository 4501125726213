<template>
  <section class="flex flex-col">
    <BarCode
      v-if="concession.codes?.[0]?.__typename === 'Barcode'"
      :image="concession.codes[0]?.image"
      :code="concession.codes[0]?.code"
    />

    <span v-text="concessionString" />
    <span v-html="concession.concession.description" />
  </section>
</template>

<script setup lang="ts">
import type { OrderConcession } from '#gql/default'

interface Props {
  concession: OrderConcession
}

const { concession } = defineProps<Props>()

const concessionString = computed(() => {
  return `${concession.quantity}x ${concession.concession.name}`
})

defineOptions({
  name: 'OrderConcession',
})
</script>
