<template>
  <OrderConfirmationOrderOverviewWrapper
    :order
    :show-presale-disclaimer="false"
  >
    <div class="grid grid-cols-3 py-2 print:text-black">
      <span v-text="t('cinema')" />
      <span class="col-span-2" v-text="cinemaString" />

      <span v-text="t('orderNumber')" />
      <span class="col-span-2" v-text="order.orderNumber" />

      <span v-text="t('vouchers')" />
      <div
        v-for="(item, index) in purchaseData.items"
        :key="index"
        class="col-span-2"
      >
        <span :key="index" v-text="`${item.quantity}x ${item.itemName}`" />
      </div>

      <span v-text="t('validUntil')" />
      <span class="col-span-2" v-text="d(order.vouchers[0].validUntil)" />
    </div>
  </OrderConfirmationOrderOverviewWrapper>
</template>

<script setup lang="ts">
import type { PurchaseData } from '@account/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

const { order, purchaseData } = defineProps<Props>()

const { t, d } = useI18n()

const cinemaString = formatCinemaAddressFromOrder(order)

defineOptions({
  name: 'OrderConfirmationOrderOverviewVoucher',
})
</script>

<i18n>
de:
  cinema: "Kino"
  orderNumber: "Bestellnummer"
  vouchers: "Gutscheine"
  validUntil: "Gültig bis"
es:
  cinema: "Cine"
  orderNumber: "Número de pedido"
  vouchers: "Vales"
  validUntil: "Válido hasta"
en:
  cinema: "Cinema"
  vouchers: "Vouchers"
  validUntil: "Valid until"
</i18n>
