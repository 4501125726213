<script setup lang="ts">
const { locale } = useI18n()

defineOptions({
  name: 'IconAppleWallet',
})
</script>

<template>
  <SvgAppleWalletAddDe
    v-if="locale === 'de'"
    class="aspect-auto w-36 print:hidden"
  />
  <SvgAppleWalletAddEs
    v-if="locale === 'es'"
    class="aspect-auto w-36 print:hidden"
  />
</template>
