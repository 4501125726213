<script setup lang="ts">
const { icons } = useDesign()

defineOptions({
  name: 'LoadingIndicator',
})
</script>

<template>
  <Icon :name="icons.LOADING" class="fill-current" />
</template>
