<template>
  <PageContainer>
    <PageGrid>
      <PageGridColumn>
        <PageHeading :title="t('page.title')" />
        <div id="kinoheld-eframe" />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const { $gtm } = useNuxtApp()

usePageMetas({
  title: t('page.title'),
})

onMounted(() => {
  $gtm.trackPageView()
})

useLegacyWidget({
  iframeName: 'kinoheld-widget',
  cinemaPath: `user/index`,
  noChange: true,
})

defineI18nRoute({
  paths: {
    en: '/account',
    de: '/konto',
    es: '/cuenta',
  },
})

defineOptions({
  name: 'AccountPage', 
})
</script>

<i18n>
de:
  page:
    title: "Konto"
es:
  page:
    title: "Cuenta"
</i18n>
