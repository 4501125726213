<template>
  <section class="flex flex-col">
    <div class="relative w-full">
      <h1
        class="dark:text-neutral-light-2 text-3xl print:text-black print:dark:text-black"
        v-html="ct('THANKYOU_H1', null, t('title'))"
      />
    </div>

    <Divider class="print:hidden" />

    <span
      class="mt-2 print:hidden"
      v-html="
        ct(
          'THANKYOU_SUBHEAD',
          { email: order.contact.email, contactSupportLink },
          emailSendText
        )
      "
    />
  </section>
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
  showPrintText?: boolean
}

const { order, showPrintText = true } = defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()

const contactSupportLink = `<a class="underline hover:text-primary" href="${t('contactPath')}" target="${LINK_TARGET.BLANK}">${t('support')}</a>`

const emailSendText = computed(() =>
  order?.type === 'CARDCHARGING'
    ? t('emailSendTextCardCharging', {
        email: order.contact.email,
        contactSupportLink,
      })
    : t('emailSendText', { email: order.contact.email, contactSupportLink }) +
      (showPrintText ? t('emailPrintText') : '')
)

defineOptions({
  name: 'OrderConfirmationHeading',
})
</script>

<i18n>
de:
  title: "Vielen Dank! Die Bestellung wurde erfolgreich abgeschlossen!"
  emailSendText: "Eine E-Mail wurde an {email} versendet. Sollte die E-Mail innerhalb von 10 Minuten nicht eintreffen, empfiehlt es sich, den Spam-Ordner zu überprüfen oder den {contactSupportLink} zu kontaktieren."
  emailSendTextCardCharging: "Eine Email wurde an {email} versendet."
  emailPrintText: "Alternativ lässt sich auch diese Seite ausdrucken oder die Barcodes abfotografieren (bitte in guter Qualität und ohne Verwacklungen), um sie im Kino vorzuzeigen."
  contactPath: "/kontakt"
  support: "kinoheld Support"
es:
  title: "Muchas gracias por confiar en nosotros para hacer tu compra, esta es tu entrada. ¡Disfruta de la película!"
  emailSendText: "Un email con Tu código descuento ha sido enviado a {email}. Si el email no llega en 10 minutos, por favor compruebe su carpeta de spam o contacta con nuestro soporte."
  emailSendTextCardCharging: "Un email con Tu código descuento ha sido enviado a {email}."
  emailPrintText: "También puedes imprimir esta página o tomar fotos de los códigos de barras (asegúrate de que sean de buena calidad y sin movimientos) para mostrarlos en el cine."
  contactPath: "/contacto"
  support: "soporte de entradas"
en:
  title: "Thank you for trusting us to make your purchase, this is your ticket. Enjoy the movie!"
  emailSendText: "To pass to the cinema, you can print this ticket or show the barcode/QR code below (please ensure that the photo is in good quality and not blurry). Additionally, you will receive an email at {email} that you can also use as a ticket."
  emailSendTextCardCharging: "An email with your discount code has been sent to {email}."
  emailPrintText: "Alternatively, you can also print this page or take photos of the barcodes (please ensure they are of good quality and not blurry) to present them at the cinema."
  contactPath: "/contact"
  support: "kinoheld support"
</i18n>
