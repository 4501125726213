import extractColorValue from '@theme/utils/extract-color-value'
import getBackgroundStyles from '@theme/utils/get-background-styles'
import getImage from '@base/utils/get-image'

import type { ImageOptions } from '@nuxt/image'
import type { NuxtApp } from '#app'
import type { Design } from '#gql/default'

export interface DesignState extends Design {
  id: string
  cssVars?: string
  customCss?: string
  isDarkTheme: boolean
  background?: Design['background']
  fontStack?: Design['fontStack']
}

export default function useDesign() {
  const { isWidgetMode } = useWidgetMode()
  const design = useState<DesignState>('design', () => ({
    id: '',
    cssVars: undefined,
    customCss: undefined,
    isDarkTheme: false,
    background: undefined,
    fontStack: undefined,
  }))

  const { $img } = useNuxtApp() as NuxtApp & {
    $img: (src: string, options: ImageOptions) => string
  }

  const themeColor = computed(() =>
    design.value?.cssVars
      ? `rgb(${extractColorValue(design.value.cssVars, '--color-header')})`
      : '#000',
  )

  const imgUrl = computed(() => {
    if (!design.value?.background) {
      return ''
    }

    const image = getImage(design.value, 'background.image', '')

    if (!image.src) {
      return ''
    }

    return $img(image.src, {
      width: image.width,
      height: image.height,
      quality: design.value.background.quality ?? 75,
      format: 'webp',
      blur: design.value.background.blur ?? 0,
    })
  })

  function applyDesign() {
    if (!design.value) {
      return
    }

    const headConfig = {
      meta: [
        {
          key: 'theme-color',
          name: 'theme-color',
          content: themeColor.value,
        },
      ],
      style: [
        {
          key: 'css-vars',
          innerHTML: `
            /* designId: ${design.value.id} */
            :root {
              ${design.value.cssVars ?? ''}
              --body-background-image-url: url(${imgUrl.value});
            }
          `.trim(),
        },
        {
          key: 'custom-css',
          innerHTML: `
            /* designId: ${design.value.id} */
            ${design.value.customCss ?? ''}
          `.trim(),
        },
      ],
      bodyAttrs: {
        tagDuplicateStrategy: 'replace',
        class: [
          'bg-body',
          isWidgetMode.value && 'widget',
          design.value.isDarkTheme && 'dark',
          `font-${design.value.fontStack}`,
          design.value.background &&
            'lg:bg-[image:var(--body-background-image-url)]',
        ]
          .filter(Boolean)
          .join(' '),
        style: getBackgroundStyles(design.value.background),
      },
    }

    useHead({
      meta: headConfig.meta,
      style: headConfig.style,
      bodyAttrs: {
        class: headConfig.bodyAttrs.class,
        style: headConfig.bodyAttrs.style,
        tagDuplicateStrategy: 'replace' as const,
      },
    })
  }

  const icons = computed(() => {
    return {
      ACCOUNT_CIRCLE_OUTLINE: 'solar:user-circle-outline',
      TOAST_ALERT: 'iconamoon:attention-circle-light',
      TOAST_INFO: 'iconamoon:information-circle-light',
      TOAST_SUCCESS: 'iconamoon:check-circle-1-light',
      BOOKMARK_OUTLINE: 'iconoir:bookmark',
      BOOKMARK_SOLID: 'iconoir:bookmark-solid',
      COUNTDOWN: 'iconamoon:clock-light',
      CART_EMPTY: 'solar:cart-cross-linear',
      CART_OUTLINE: 'solar:cart-large-minimalistic-linear',
      CART_FILL: 'solar:cart-large-minimalistic-bold',
      HOME: 'iconamoon:home-light',
      GPS_EMPTY: 'ph:gps',
      GPS_FILL: 'ph:gps-fix-fill',
      CHEVRON_RIGHT: 'iconamoon:arrow-right-2-light',
      CHEVRON_LEFT: 'iconamoon:arrow-left-2-light',
      CHEVRON_UP: 'iconamoon:arrow-up-2-light',
      CHEVRON_DOWN: 'iconamoon:arrow-down-2-light',
      CHECK: 'fluent:checkmark-20-filled',
      CHECK_CIRCLE_OUTLINE: 'iconamoon:check-circle-1-light',
      RADIO: 'fluent-mdl2:radio-bullet',
      INFO_OUTLINE: 'iconamoon:information-circle-light',
      MOVIE_ROLL: 'mdi:movie-roll',
      GLOBE: 'mynaui:globe',
      TICKET_OUTLINE: 'fluent:ticket-diagonal-24-regular',
      TICKET_FILL: 'fluent:ticket-diagonal-24-filled',
      TICKET_EXPRESS: 'streamline:tickets-solid',
      THEATER: 'hugeicons:curtains',
      MAP_MARKER: 'heroicons:map-pin-solid',
      MAP_MARKER_OUTLINE: 'heroicons:map-pin',
      PLAY: 'solar:play-bold',
      PLAY_OUTLINE: 'solar:play-line-duotone',
      PLAY_CIRCLE: 'solar:play-circle-bold',
      PLAY_CIRCLE_OUTLINE: 'solar:play-circle-linear',
      PAUSE_CIRCLE_OUTLINE: 'solar:pause-circle-linear',
      CLOSE: 'iconamoon:close-light',
      STAR: 'solar:star-bold',
      STAR_OUTLINE: 'solar:star-line-duotone',
      STAR_CIRCLE: 'solar:star-circle-bold',
      SETTINGS_OUTLINE: 'iconamoon:settings-light',
      LOADING: 'line-md:loading-twotone-loop',
      MAIL_SENT: 'mingcute:mail-send-line',
      MAIL: 'mingcute:mail-line',
      TRASH_BIN: 'solar:trash-bin-minimalistic-linear',
      PRINTER: 'solar:printer-linear',
      GALLERY_IMAGE: 'iconoir:media-image-list',
      GALLERY_TRAILER: 'iconoir:media-video-list',
      MENU_BURGER_HORIZONTAL: 'iconamoon:menu-burger-horizontal-light',
      MINUS: 'iconamoon:sign-minus-light',
      PLUS: 'iconamoon:sign-plus-light',
      EDIT: 'iconamoon:edit-light',
      QUESTION_MARK: 'ph:question-mark-light',
      EXCLAMATION_MARK: 'ph:exclamation-mark-light',
      LINK_EXTERNAL: 'iconamoon:link-external-light',
      SEAT: 'solar:armchair-outline',
      PHONE: 'solar:phone-linear',
      MAGNIFYING_GLASS: 'iconamoon:search-light',
    }
  })

  return {
    design,
    icons,
    applyDesign,
  }
}
